import "./App.css";
import { useEffect, useState } from "react";
function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentIpAddress, setCurrentIpAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [workspace,setWorkspace] = useState("")
  console.log(process.env.REACT_APP_ENDPOINT,"ENDPOINTENDPOINT")
  useEffect(() => {
    const fetchIpAddress = async () => {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setCurrentIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    };

    fetchIpAddress();
  }, []);

  const fieldsStyle = {
    padding: "10px",
    width: "250px",
    borderRadius: "100px",
    outline: "none",
    boxShadow: "none",
    border: "none",
  };

  const onFormSubmit = (e) => {
    try {
      e.preventDefault();
      const username = e.target.username.value.trim();  // Note: Correct casing
      const password = e.target.password.value.trim();
  
      fetch(`${process.env.REACT_APP_ENDPOINT}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", 
        },
        body: JSON.stringify({
          username,  
          password,
        }),
      })
        .then(async (res) => {
          if (!res.ok) {
            throw new Error("Invalid credentials or server error");
          }
          const data = await res.json(); // Parse JSON response
          setWorkspace(data.workspace);
        })
        .catch((err) => {
          console.error(err);
          alert("Login failed: " + err.message);
        });
    } catch (error) {
      console.error(error);
      alert("Something terribly went wrong :(");
    }
  };
  
  const addIP = (e) => {
    e.preventDefault();
    const ipValue = (e.target.ip.value + "").trim();
    const ipName = (e.target.ip_name.value + "").trim();
    if (!ipName) {
      alert("Enter a valid IP name");
      return;
    }
    if (!/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/.test(ipValue)) {
      alert("Invalid IPv4");
      return;
    }

    setIsLoading(true);
    if(workspace === "dev"){
      fetch(process.env.REACT_APP_AWS_URL_DEV, {
        method: "POST",
        mode: "no-cors",
        body: JSON.stringify({
          my_ip: ipValue,
          name: e.target.ip_name.value,
        }),
      })
        .then(() => {
          setIsLoading(false);
          alert("SUCCESS: IP added!");
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
          alert("ERROR: Failed to Add IP!");
        });
    }else if(workspace === "sales"){
      fetch(process.env.REACT_APP_AWS_URL_SALES, {
        method: "POST",
        mode: "no-cors",
        body: JSON.stringify({
          my_ip: ipValue,
          name: e.target.ip_name.value,
        }),
      })
        .then(() => {
          setIsLoading(false);
          alert("SUCCESS: IP added!");
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
          alert("ERROR: Failed to Add IP!");
        });
    }
  
  };
  
  return (
    <div className="App">
      <header className="App-header">
        <img
          src={
            "https://sweetp-user-uploads.s3.eu-west-2.amazonaws.com/stage%20/%201685586307248_profile"
          }
          alt="logo"
        />
        {workspace === "dev" ? (
          <>
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                gap: "15px",
              }}
              onSubmit={addIP}
            >
              <h3>ADD IPV4 Dev</h3>
              <h5>Current IP : {currentIpAddress}</h5>
              <input
                name="ip_name"
                placeholder="Ip name"
                required
                minLength={4}
                style={fieldsStyle}
                type="text"
              />
              <input
                name="ip"
                placeholder="Your Ip address"
                required
                defaultValue={currentIpAddress}
                style={fieldsStyle}
                type="text"
              />

              <input
                style={{
                  ...fieldsStyle,
                  background:
                    "linear-gradient(90deg, #5351FC 0%, #19A9FC 100%)",
                  width: "50%",
                  cursor: "pointer",
                  color: "white",
                  fontWeight: "bold",
                  opacity: isLoading ? 0.5 : 1,
                }}
                disabled={isLoading}
                type="submit"
                value={isLoading ? "Adding..." : "Submit"}
              />
            </form>
          </>
        ) :
        workspace === "sales" ? 
       (
        <form
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "15px",
        }}
        onSubmit={addIP}
      >
        <h3>ADD IPV4 Sales</h3>
        <h5>Current IP : {currentIpAddress}</h5>
        <input
          name="ip_name"
          placeholder="Ip name"
          required
          minLength={4}
          style={fieldsStyle}
          type="text"
        />
        <input
          name="ip"
          placeholder="Your Ip address"
          required
          defaultValue={currentIpAddress}
          style={fieldsStyle}
          type="text"
        />

        <input
          style={{
            ...fieldsStyle,
            background:
              "linear-gradient(90deg, #5351FC 0%, #19A9FC 100%)",
            width: "50%",
            cursor: "pointer",
            color: "white",
            fontWeight: "bold",
            opacity: isLoading ? 0.5 : 1,
          }}
          disabled={isLoading}
          type="submit"
          value={isLoading ? "Adding..." : "Submit"}
        />
      </form>
        ):
        (
          <form
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
          }}
          onSubmit={onFormSubmit}
        >
          <h1>Login</h1>
          <input
            required
            name="username"
            placeholder="Username"
            style={fieldsStyle}
            type="text"
          />
          <input
            required
            name="password"
            placeholder="Password"
            style={fieldsStyle}
            type="password"
          />
          <input
            style={{
              ...fieldsStyle,
              background: "linear-gradient(90deg, #5351FC 0%, #19A9FC 100%)",
              width: "50%",
              cursor: "pointer",
              color: "white",
              fontWeight: "bold",
            }}
            type="submit"
          />
        </form>
        )
        }
      </header>
    </div>
  );
}

export default App;
